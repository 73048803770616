<script setup lang="ts">
import AppIcon from "~/components/app/AppIcon.vue";
import { useFeatureFlags, useStringFeatureFlags } from "~/composables/useFeatureFlags";
import { useFeatureFlagStore } from "~/stores/useFeatureFlagStore";

const runtimeConfig = useRuntimeConfig();
const isActive = runtimeConfig.public.appEnv !== "prod";

const dayjs = useDayjs();
const { $pinia } = useNuxtApp();
const { setLocale, locales, locale: currentLocale } = useNuxtApp().$i18n;

const showABTestSwitcher = ref(false);

const { hasFreeTrial, hasSkippedFunnel } = useConditions();
const { userType } = storeToRefs(useUserStore());

const { sessionId } = storeToRefs(useBiTrackingStore());

const featureFlags = useFeatureFlags();
const stringFeatureFlags = useStringFeatureFlags();
const { featuresForcedValues, featuresForcedStringValues } = storeToRefs(useFeatureFlagStore($pinia));

const conditions = computed(() => [
  {
    name: "Login status",
    type: "buttons",
    value: userType.value,
    options: [
      { name: "Guest", value: "guest" },
      { name: "User", value: "user" },
      { name: "Token user", value: "tokenUser" },
    ],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    change: async (value: any): Promise<void> => {
      if (value === "guest") {
        useTokenUserStore().$reset();
        useUserStore().user = undefined;
        useAuthStore().$reset();
      }

      if (value === "user") {
        await useAuthStore().login("yz-maintain-31-DE-c14e580a3bfe5e3aeeac@byom.de", "yztest");
        await useUserStore().fetchUser();
        useTokenUserStore().$reset();
      }

      if (value === "tokenUser") {
        useTokenUserStore().token = "c4aecd1fccd58e31618f00fda3df0e72bc6c38754c6533e72d1c82b0126414d5";
        await useTokenUserStore().fetchTokenUser();
        useUserStore().user = undefined;
        useAuthStore().$reset();
      }
    },
  },
  {
    name: "Free trial active",
    type: "buttons",
    value: hasFreeTrial.value,
    options: [
      { name: "Yes", value: true },
      { name: "No", value: false },
    ],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    change: (value: any): void => {
      useCheckoutStore().checkoutParams.trial_days = value ? 7 : 0;
    },
  },
  {
    name: "Discount code",
    type: "select",
    value: useDiscountStore().code,
    options: [
      { name: "Dominique", value: "domi-west" },
      { name: "Name only", value: "name-only" },
      { name: "HelloFresh", value: "hf-wzu9p" },
      { name: "Without partner content", value: "no-content" },
      { name: "None", value: "" },
    ],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    change: (value: any): void => {
      useDiscountStore().code = value;
    },
  },
  {
    name: "Has skipped funnel",
    type: "buttons",
    value: hasSkippedFunnel.value,
    options: [
      { name: "Yes", value: true },
      { name: "No", value: false },
    ],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    change: (value: any): void => {
      useOnboardingStore().skippedOnboarding = value;
    },
  },
  {
    name: "Goal",
    type: "buttons",
    value: useOnboardingStore().registrationParams.goal,
    options: [
      { name: "Lose", value: "lose" },
      { name: "Gain", value: "gain" },
      { name: "Maintain", value: "maintain" },
      { name: "None", value: undefined },
    ],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    change: (value: any): void => {
      useOnboardingStore().registrationParams.goal = value;
      const user = useUserStore().user;
      if (user) {
        user.goal = value;
      }
    },
  },
]);

async function changeLanguage(payload: Event) {
  const locale = (payload.target as HTMLSelectElement).value;

  dayjs.locale(locale);
  // @ts-ignore
  await setLocale(locale);
}
</script>

<template>
  <div v-if="isActive" class="fixed inset-y-0 right-0 z-50">
    <div
      class="absolute bottom-0 right-1 cursor-pointer leading-none"
      @click="showABTestSwitcher = !showABTestSwitcher"
    >
      <app-icon icon="teenyicons:ab-testing-solid" class="align-bottom" width="50px"></app-icon>
    </div>
    <div
      v-if="showABTestSwitcher"
      class="absolute bottom-14 right-0 top-0 z-10 flex flex-col gap-3 overflow-y-auto rounded-bl-lg bg-white p-3 shadow-lg"
    >
      <div>
        <h4>Session</h4>
        <span class="text-xs">{{ sessionId }}</span>
      </div>
      <hr class="m-0" />
      <div>
        <h4>Language</h4>
        <select class="w-full rounded-lg" @change="changeLanguage">
          <option
            v-for="locale in locales"
            :key="locale.code"
            :value="locale.code"
            :selected="locale.code === currentLocale"
          >
            {{ locale.name }}
          </option>
        </select>
      </div>
      <hr class="m-0" />
      <div>
        <h4>Current User</h4>
        <div class="flex flex-col gap-3">
          <div v-for="condition in conditions" :key="condition.name" class="flex flex-col">
            <div class="text-sm">{{ condition.name }}</div>
            <select
              v-if="condition.type === 'select'"
              class="w-full rounded-lg"
              :value="condition.value"
              @change="condition.change(($event.target as HTMLSelectElement).value ?? '')"
            >
              <option v-for="(option, key) in condition.options" :key="key" :value="option.value">
                {{ option.name }}
              </option>
            </select>

            <div v-else class="flex items-stretch justify-items-center">
              <div
                v-for="(option, index) in condition.options"
                :key="option.name"
                type="button"
                class="grow text-nowrap border-y border-r border-yz-blue-600 px-4 py-2 text-center text-sm"
                :class="{
                  'bg-yz-blue-600 text-white': condition.value === option.value,
                  'rounded-s-lg border-l': index === 0,
                  'rounded-e-lg': index === condition.options.length - 1,
                }"
                @click="condition.change(option.value)"
              >
                {{ option.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="m-0" />
      <div>
        <h4>A/B Tests</h4>
        <div class="flex flex-col gap-3">
          <div v-for="(value, name, index) in featureFlags" :key="index" class="flex flex-col gap-1">
            <div class="text-nowrap text-sm">{{ name }}</div>
            <div class="flex">
              <div
                class="grow cursor-pointer rounded-s-lg border-y border-l border-yz-blue-600 px-4 py-2 text-center text-sm"
                :class="{
                  underline: value.isOff({ silent: true, ignoreStore: true }).value,
                  'bg-yz-blue-600 text-white': value.isOff({ silent: true }).value,
                }"
                @click="featuresForcedValues[name] = false"
              >
                Baseline
              </div>
              <div
                class="grow cursor-pointer rounded-e-lg border border-yz-blue-600 px-4 py-2 text-center text-sm"
                :class="{
                  underline: value.isOn({ silent: true, ignoreStore: true }).value,
                  'bg-yz-blue-600 text-white': value.isOn({ silent: true }).value,
                }"
                @click="featuresForcedValues[name] = true"
              >
                Variant
              </div>
            </div>
          </div>
          <div v-for="(value, name, index) in stringFeatureFlags" :key="index" class="flex flex-col gap-1">
            <div class="text-nowrap text-sm">{{ name }}</div>
            <div class="flex">
              <div
                v-for="(option, optionIndex) in value.options"
                :key="optionIndex"
                class="grow cursor-pointer border-y border-r border-yz-blue-600 px-4 py-2 text-center text-sm"
                :class="{
                  underline: value.getStringValue({ silent: true, ignoreStore: true }).value === option,
                  'bg-yz-blue-600 text-white': value.getStringValue({ silent: true }).value === option,
                  'rounded-s-lg border-l': optionIndex === 0,
                  'rounded-e-lg': optionIndex === value.options.length - 1,
                }"
                @click="featuresForcedStringValues[name] = option"
              >
                {{ option }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
